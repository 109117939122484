:root {
  --primary-color: #458ff6;
  --tertiary-color: #c6effb;
  --grey: #b6b1b1;
  --dark: #000000;
  --background-color: #f0f0f0;
  --white: #ffffff;
  --secondary-color: #bdfc08;
  --bs-body-font-family: "Lato", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  /* font-family: 'JohnRounded', sans-serif!important; */
  font-family: "Lato", sans-serif !important;
}

#root {
  height: 100%;
}

.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fffafa;
}
.screen_container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  width: 40%;
  padding-block: 20px;
  padding-inline: 30px;
  margin-block: 50px;
  border: 1px solid var(--grey);
  border-radius: 20px;
  box-shadow: 9 9 1 0.1rem var(--grey);
}

.handleQR {
  width: 75%;
  height: 75%;
  object-fit: contain;
}

.logo {
  height: 30px;
  width: 30px;
}
.title {
  font-weight: 550;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  padding-block: 16px;
}

.bouncing-loader > div {
  width: 12px;
  height: 12px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: var(--primary-color);
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-12px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.input {
  color: #7c7c7f !important;
  background-color: #f0f0f0 !important;
  font-weight: 600 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-block: 8px !important;
}

.input::placeholder {
  color: #888888 !important;
}

.toaster {
  border: 1px solid var(--grey) !important;
  font-size: 14px;
}

.header-container {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}

.toaster button {
  color: var(--dark) !important;
}

.inputWidth {
  width: 49%;
}

.forgetButton {
  font-size: 1em !important;
  background: linear-gradient(to right, #d2307b, #f1ac30);
  -webkit-background-clip: text;
  color: transparent !important;
  display: inline-block !important;
  -webkit-text-fill-color: transparent;
  font-weight: 600 !important;
  margin-bottom: 0;
}

.bg-dark {
  border: none !important;
}

.actionBtn {
  background: linear-gradient(to right, #d2307b, #f1ac30);
  border: none !important;
  font-size: 1em !important;
  font-weight: 600 !important;
  padding-block: 8px !important;
}

.fbbg {
  background-color: #3b579d !important;
  border: none !important;
  padding-block: 8px !important;
  font-weight: 600 !important;
}

.googlebg {
  background-color: var(--white) !important;
  border: 1px solid var(--grey) !important;
  padding-block: 8px !important;
  color: var(--dark) !important;
  font-weight: 600 !important;
}
.input_container {
  background-color: var(--white);
}

.signupbtn {
  padding-block: 10px !important;
  border: none !important;
  outline: none !important;
  position: relative !important;
  border-radius: 5px !important;
  background: linear-gradient(to right, #00ffa3, #dc1fff) !important;
  z-index: 1;
}

.signupbtn::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: var(--white);
  border-radius: 4px;
  z-index: -1;
}

.signupbtn:hover {
  border-color: transparent;
  border-image: linear-gradient(to right, #ff7e5f, #feb47b) 1;
}

.name {
  bottom: 50px;
}

.splashLogo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.circle {
  display: flex;
  position: relative;
  height: 130px;
  width: 130px;
  align-items: center;
  justify-content: center;
}

.loader {
  border: none;
  position: absolute;
  border-radius: 50%;
  border-top: 4px solid var(--primary-color);
  width: 130px;
  height: 130px;
  animation: spin 1s linear infinite;
  top: 0 !important;
  left: 0 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profile-btn {
  border: none !important;
  outline: none !important;
  position: relative !important;
  border-radius: 34px !important;
  border: 0.5px solid var(--primary-color) !important;
  background-color: var(--white) !important;
  z-index: 1;
  cursor: pointer;
}

.profile-btn:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.profile-btn:hover * {
  color: white !important;
}

.profile-btn:hover svg {
  fill: white !important;
  /* stroke: white !important;  */
}

.profileGrBox {
  width: 75%;
  height: 75px;
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--tertiary-color)
  );
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  cursor: pointer;
  margin-inline: auto;
  border-radius: 40px;
}

.single-package {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.single-package-bg {
  background-color: #0a0b10;
}

.single-form {
  width: 80%;
}

.paybtn {
  width: 80%;
  padding-block: 10px !important;
  border: none !important;
  outline: none !important;
  position: relative !important;
  border-radius: 5px !important;
  background: linear-gradient(to right, #c5407a, #e9a342) !important;
  z-index: 1;
  text-transform: uppercase;
}

.single-form {
  width: 80%;
}

.shopnow-input {
  color: #000000 !important;
  font-weight: 400 !important;
  letter-spacing: 1 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-block: 10px !important;
  background-color: #a09ea9 !important;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-group {
  transition: max-height 0.5s ease;
  overflow: hidden;
}

.card-hover{
  border: 2px solid var(--primary-color)!important;
}

.iconcon{
  background-color: var(--primary-color);
  color: var(--background-color);
  width: 60px;
  height: 60px;
}

.card-hover:hover {
  background: linear-gradient(135deg, var(--primary-color), #0045a5);
  transform: translateY(-5px); 
  transition: background 0.3s ease, transform 0.3s ease;
  color: var(--background-color)!important;
  cursor: pointer;
}

.card-hover:hover .card-title {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.card-hover:hover .iconcon {
  background-color: var(--background-color);
  color: var(--primary-color);
}


.back-button:hover {
  background-color: #0056b3;
}

.shopnow-input-error {
  border-width: 1px !important;
  border-color: red !important;
  border-style: solid !important;
}

.shopnow-input::placeholder {
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.section1 {
  width: 74%;
}
.section2 {
  width: 10%;
}

.profile-image {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.request-notification-container {
  background-color: var(--white);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.request-info {
  margin: 0;
  color: var(--dark);
  font-size: 16px;
  font-weight: 600;
}
.request-time {
  color: var(--grey);
  font-size: 12px;
  font-weight: 500;
}

.request-actions button {
  min-width: 80px;
  border-radius: 20px;
}

.requester-profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.notification-container {
  background-color: var(--white);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.notification-container:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.requester-profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.notification-content p {
  margin: 0;
  color: #4f5052;
  font-size: 16px;
  font-weight: 500;
}

.notification-content small {
  color: #767677;
  font-size: 14px;
}

.handleprofiles {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.departed-profile-border {
  width: 85%;
  border: 1px solid var(--primary-color);
  padding: 5px;
  border-radius: 10px;
}

.comments-section {
  margin-top: 1rem;
  padding: 10px;
  background: #fffafa;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  padding-top: 20px;
}

.profileGrBox {
  width: 75%;
  height: 75px;
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--tertiary-color)
  );
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  cursor: pointer;
  margin-inline: auto;
  border-radius: 40px;
}

.single-package {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.single-package-bg {
  background-color: #0a0b10;
}

.single-form {
  width: 80%;
}

.paybtn {
  width: 80%;
  padding-block: 10px !important;
  border: none !important;
  outline: none !important;
  position: relative !important;
  border-radius: 5px !important;
  background: linear-gradient(to right, #c5407a, #e9a342) !important;
  z-index: 1;
  text-transform: uppercase;
}

.single-form {
  width: 80%;
}

.section1 {
  width: 74%;
}
.section2 {
  width: 10%;
}

.profile-image {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.file-input {
  color: var(--dark) !important;
  background-color: #6c6b6e !important;

  border: 1px solid #6c6b6e !important;
}

.file-input::file-selector-button {
  background-color: #a09ea9 !important;

  border: none !important;
  color: var(--dark) !important;
}

.profile-pic-container {
  width: 130px;
  height: 130px;
}

.banner-container {
  width: 100%;
  height: 180px;
}

.banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.edit-icon {
  top: 0;
  right: 15px;
  /* border: 2px solid var(--dark); */
  border-radius: 50%;
  cursor: pointer;
}

.edit-icon-banner {
  top: -6px;
  right: 0px;
  /* border: 2px solid var(--dark); */
  border-radius: 50%;
  cursor: pointer;
}

.crop-container {
  position: relative;
  height: 250px;
  width: 100%;
}

.modal-content {
  background-color: var(--background-color) !important;
}
.modal-title {
  color: var(--dark) !important;
}

.btn-close {
  /* --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) !important; */
}

.modal-header {
  border-bottom-color: var(--dark) !important;
}

.modal-footer {
  border-top-color: var(--dark) !important;
}

.reactEasyCrop_Container {
  background-color: var(--white) !important;
}

.light {
  font-weight: 200;
  font-size: 14px;
}

.gradientBox {
  width: 100%;
  height: 75px;
  background: linear-gradient(to right, #c5407a, #e9a342);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  cursor: pointer;
}

.single-package {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.single-package-bg {
  background-color: #0a0b10;
}

.single-form {
  width: 80%;
}

.paybtn {
  width: 80%;
  padding-block: 10px !important;
  border: none !important;
  outline: none !important;
  position: relative !important;
  border-radius: 5px !important;
  background: linear-gradient(to right, #c5407a, #e9a342) !important;
  z-index: 1;
  text-transform: uppercase;
}

.single-form {
  width: 80%;
}

.section1 {
  width: 74%;
}
.section2 {
  width: 10%;
}

.profile-img {
  height: 34px;
  width: 34px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.active {
  background-color: var(--primary-color) !important;
}

.align-middle {
  vertical-align: middle;
}

.admin-btn:hover,
.admin-btn:focus {
  background-color: transparent !important;
}

@media only screen and (max-width: 600px) {
  .btnCon {
    width: 60% !important;
  }
}

@media only screen and (min-width: 1024px) {
  .screen_container {
    width: 70%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .screen_container {
    width: 70%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .screen_container {
    width: 95%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .screen_container {
    width: 98%;
  }
}
